.contact-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Header image styles */
  .contact-header {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust the height as needed */
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Contact content styles */
  .contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Booking info styles */
  .booking-info {
    flex: 1;
    min-width: 250px;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  }
  
  .booking-info h2 {
    font-size: 40px;
    margin-bottom: 20px;
    border-bottom: 2px solid #1db954;
    padding-bottom: 10px;
  }
  
  .booking-info p {
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .serious-inquiries {
    color: #c8c8c8;
    font-size: 18px;
    margin-top: 35px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .booking-info a {
    color: #1db954;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .booking-info a:hover {
    color: #1ed760;
  }
  
  .booking-info .icon {
    margin-right: 10px;
    color: #1db954;
  }
  
  /* Contact form styles */
  .contact-form {
    flex: 2;
    min-width: 250px;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  }

  .contact-form h2 {
    font-size: 40px;
    text-transform: uppercase;
  }

  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    background-color: #333;
    color: #fff;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #1db954;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #1ed760;
  }
  
  .required {
    color: red;
  }
  
  /* Success banner styles */
  .success-banner {
    position: fixed;
    top: -50px;
    left: 0;
    width: 100%;
    background-color: #1db954;
    color: white;
    text-align: center;
    padding: 10px;
    transition: top 0.3s;
  }
  
  .success-banner.show {
    top: 0;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
    }
  
    .booking-info,
    .contact-form {
      margin-right: 0;
    }
  }