@media screen and (max-width: 960px) {
  .hero-container .out-now-line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 0 10px;
  }

  .hero-container .subheading {
    font-size: 14px;  /* Increased font size from too small, adjust as necessary */
    text-transform: uppercase;
    margin: 0 5px;
    white-space: normal;  /* Allow normal text flow if nowrap was too restrictive */
    overflow: visible;  /* Make overflow visible for troubleshooting */
  }

  .hero-container{
    background: url('../../imgs/ks-intro.gif') center center/cover no-repeat;
    
  }

  .text-content {
      width: 90%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .hero-container h1 {
      font-size: 56px;
      text-align: center;
  }
}



@media screen and (max-width: 768px) {
  .hero-container .out-now-line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 0 10px;
  }

  .hero-container{
    background: url('../../imgs/ks-intro.gif') center center/cover no-repeat;
    
  }

  .hero-container .subheading {
    font-size: 14px;  /* Consistent size for better legibility */
    text-transform: uppercase;
    margin: 0 5px;
    white-space: normal;
    overflow: visible;
  }

  .text-content {
      width: 90%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .hero-container h1 {
      font-size: 48px;  /* Adjusted for better fit on smaller screens */
      text-align: center;
  }
}

