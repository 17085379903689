body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #fff;
  margin: 0;
  padding: 0;
}

.artist-detail {
  max-width: 1200px;
  margin: 0 auto;
}

.artist-hero {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.artist-hero-content {
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.artist-name-container {
  margin-bottom: 10px;
}

.artist-name {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.verified-artist {
  font-size: 16px;
  color: #1db954;
}

.monthly-listeners {
  font-size: 14px;
  color: #b3b3b3;
}

.artist-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

.artist-bio {
  display: flex;
  flex-direction: column;
}

.artist-bio h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.artist-bio p {
  font-size: 16px;
  line-height: 1.6;
}

.artist-social {
  display: flex;
  margin-top: 10px;
}

.social-icon {
  font-size: 30px;
  color: #fff;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #1db954;
}

.artist-spotify {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artist-spotify iframe {
  border-radius: 8px;
  width: 100%;
  height: 380px;
  border: none;
}

.loading-spinner {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .artist-content {
    grid-template-columns: 1fr;
  }
}