/* Tilt and Zoom Effect */
.artist-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: perspective(1000px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.artist-card:hover {
  transform: scale(1.05) rotateY(10deg) rotateX(10deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.artist-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.artist-card:hover .artist-image {
  transform: scale(1.1);
}

.artist-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
  z-index: 1;
  pointer-events: none;
}

/* Artists Section */
body {
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.artists-section {
  position: relative;
  background-color: #121212;
  padding: 40px 0 0;
  text-align: center;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.artists-heading {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: #f7f7f7;
  font-family: 'Archivo Black', sans-serif;
  font-size: 160px;
  font-weight: bold;
  opacity: 0.5;
  z-index: 0;
  white-space: nowrap; /* Prevents the heading from breaking into multiple lines */
}

.artists-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  margin-top: 100px;
}

.artist-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: perspective(1000px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.artist-card:hover {
  transform: scale(1.05) rotateY(10deg) rotateX(10deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.artist-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.artist-card:hover .artist-image {
  transform: scale(1.1);
}

.artist-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
  z-index: 1;
  pointer-events: none;
}

.artist-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-size: 1.5em;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
  font-family: 'Oswald', sans-serif;
}

@media screen and (max-width: 1024px) {
  .artists-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .artists-section {
    padding: 5px 0 0;
  }

  .artists-heading {
    font-size: 100px;
    top: 20px;
  }

  .artists-grid {
    grid-template-columns: 1fr;
  }

  .artist-name {
    font-size: 1.2em;
  }
}