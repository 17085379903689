@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

.hero-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.hero-container {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;  /* Anchors content towards the bottom */
    align-items: flex-start;    /* Aligns content to the left */
    height: 100%;
    width: 100%;
    color: white;
    padding: 20px 50px 60px;  /* Adjusted padding for balanced appearance */
    margin: 0;
}

.full-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: gray;
}

.text-content {
    position: relative;
    max-width: 500px;
    margin-bottom: 30px;  /* Increased margin to prevent sticking to the bottom */
}

.hero-container h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;  /* Standard size, responsive adjustments below */
    line-height: normal;
    font-weight: 700;
    letter-spacing: -.025em;
    text-transform: uppercase;
    margin: 10px 0;
}

/* Responsive font sizes */

@media (min-width: 1200px) {
    .hero-container h1 {
        font-size: 64px;  /* Extra large screens */
    }
}

.hero-container .out-now-container {
    display: flex;
    align-items: center;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 10px;
}

.hero-container .subheading {
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 5px;
}

.hero-container .out-now-line {
    flex-grow: 1;
    height: 1px;
    background-color: white;
    margin: 0 10px;
}

.cta-buttons {
    display: flex;
    gap: 30px;
    margin-top: 20px;
}

.cta-buttons .btns {
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    background: none;
    position: relative;
    padding: 0 15px;
}

.cta-buttons .btns::after {
    content: '|';
    color: white;
    display: inline-block;
    margin-left: 15px;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.cta-buttons .btns:last-child::after {
    display: none;
}

.cta-buttons .btns:hover {
    color: gray;
}


@media (min-width: 768px) {
    .hero-container h1 {
        font-size: 48px;  /* Medium screens */
    }
}

@media (min-width: 992px) {
    .hero-container h1 {
        font-size: 56px;  /* Large screens */
    }
}
