.event-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  padding: 20px;
}

.event-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 20px;
}

.event-heading{
    color: #f5f5f5;
    font-family: 'Archivo Black', sans-serif;
    font-size: 160px;
    font-weight: bold;
    opacity: 0.3;
}

.event-details {
  margin-left: 20px;
  max-width: 600px;
  text-align: center;
}

.event-details h1 {
  font-size: 36px;
  color: #d8d8d8;
  text-align: center; 
}

.event-details p {
  color: #a7a7a7;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.event-item {
  display: flex;
  flex-direction: row; /* Change to row to align date and event title horizontally */
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; /* Space between date and event title */
}

.event-date .day {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
}

.event-date .month {
  font-size: 18px;
  color: #ccc;
  text-transform: uppercase;
}

.event-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.event-info h3 {
  font-size: 24px;
  color: #919191;
  margin: 0;
}

.ticket-button {
  background: none;
  border: 1px solid #676767;
  border-radius: 20px;
  padding: 5px 15px;
  color: #868686;
  cursor: pointer;
  margin-left: 20px;
}

.ticket-button:hover {
  background-color: #333;
  color: #fff;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading::before {
  content: '';
  border: 4px solid #f3f3f3;
  border-top: 4px solid #34db45;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 960px) {
  .event-heading {
    font-size: 5rem;
  }

  .event-item {
    flex-direction: column;
    align-items: center;
  }

  .event-date {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) {
  .event-list {
    flex-direction: row;
    text-align: left;
  }

  .event-details {
    margin-left: 40px;
    text-align: left;
  }

  .event-details h1 {
    text-align: left;
  }

  .event-item {
    flex-direction: row;
    text-align: left;
  }

  .event-info {
    align-items: flex-start;
  }
}