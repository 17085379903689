@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; /* For Firefox */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 10px 20px;
    color: white;
    font-family: 'Bebas Neue', sans-serif; /* Apply Bebas Neue font */
}

.navbar-left .logo-image {
    height: 40px; /* Adjust height as needed */
    width: auto;
    margin-bottom: 0px;
}

.navbar-center {
    display: flex;
    align-items: center;
}

.navbar-center a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif; /* Apply Bebas Neue font */
}

.navbar-center a:hover {
    color: gray;
}

.navbar-right {
    display: flex;
    align-items: center;
}

.navbar-right a {
    color: white;
    margin: 0 5px;
    font-size: 20px;
    text-decoration: none;
}

.navbar-right a:hover {
    color: gray;
}

/* MOBILE NAVBAR STYLES */
@media screen and (max-width: 768px) {
    .navbar-left,
    .navbar-center,
    .navbar-right {
        display: none; /* Hide the default navbar sections on mobile */
    }

    .navbar-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .navbar-mobile .logo-image {
        height: 30px; /* Adjust height for mobile */
        width: auto;
    }

    .navbar-mobile .menu-icon {
        font-size: 24px;
        cursor: pointer;
        color: white;
    }

    .navbar-menu {
        display: none; /* Hidden by default */
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: black;
        width: 100%;
        padding: 20px;
        position: absolute;
        top: 50px; /* Adjust to position right below the navbar */
        left: 0;
        z-index: 10;
    }

    .navbar-menu.open {
        display: flex; /* Display flex when menu is open */
    }

    .navbar-menu a {
        color: white;
        margin: 10px 0;
        font-size: 18px;
        text-decoration: none;
        font-family: 'Bebas Neue', sans-serif;
        text-transform: uppercase;
    }

    .navbar-menu a:hover {
        color: gray;
    }

    .navbar-menu .social-icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .navbar-menu .social-icons a {
        color: white;
        margin: 0 10px;
        font-size: 24px;
    }

    .navbar-menu .social-icons a:hover {
        color: gray;
    }
}

/* Ensure the mobile navbar is hidden on desktop */
.navbar-mobile,
.navbar-menu {
    display: none;
}

/* MOBILE NAVBAR STYLES */
@media screen and (max-width: 768px) {
    .navbar-mobile {
        display: flex; /* Show mobile navbar on small screens */
    }
}
