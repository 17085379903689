.footer-container {
  background-color: #000;
  color: #fff;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%; /* Ensures the logo container spans the full width */
}

.logo-image {
  height: 100px; /* Adjust the size as needed */
  width: auto;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
}

.footer-links a:hover {
  color: #ccc;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.footer-social a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #ccc;
}

.footer-bottom {
  text-align: center;
  margin-bottom: 20px;
}

.footer-bottom-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.footer-bottom-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.footer-bottom-links a:hover {
  color: #ccc;
}

.footer-copyright {
  font-size: 12px;
  color: #777;
}
